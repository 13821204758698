<!--仓库汇总表-->
<template>
  <div class="statement_box">
    <div class="statement_top_row">
      <div class="statement_left_ul">
        <div class="statement_left_li">
          <div class="statement_left_content">
            <p>库存总量</p>
            <p><span class="bold_content">{{divisionMoney(collectData.stockNum)}}</span>种</p>
          </div>
          <div class="statement_left_content">
            <p>低库存</p>
            <p><span class="bold_content">{{divisionMoney(collectData.warningNum)}}</span>种</p>
          </div>
        </div>
        <div class="statement_left_li">
          <div class="statement_left_content">
            <p>成品数</p>
            <p><span class="bold_content">{{divisionMoney(collectData.finishedProductNum)}}</span>种</p>
          </div>
          <div class="statement_left_content">
            <p>零库存</p>
            <p><span class="bold_content">{{divisionMoney(collectData.zeroNum)}}</span>种</p>
          </div>
        </div>
        <div class="statement_left_li">
          <div class="statement_left_content">
            <p>物料数</p>
            <p><span class="bold_content">{{divisionMoney(collectData.materialProductNum)}}</span>种</p>
          </div>
          <div class="statement_left_content">
            <p>超过1年库存</p>
            <p><span class="bold_content">{{divisionMoney(collectData.timeoutNum)}}</span>种</p>
          </div>
        </div>
      </div>
      <div class="statement_right_content">
        <div>库存总金额</div>
        <div><span class="bold_content">{{divisionMoney(collectData.costAmount)}}</span>万</div>
      </div>
    </div>
    <div class="statement_table_box">
      <el-table
        :data="tableData"
        size="mini"
        style="width: 100%">
        <el-table-column
          show-overflow-tooltip
          prop="name"
          label="仓库名称">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="encoding"
          label="仓库编号">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="warehouseNum"
          label="库存数量">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="occupyNumber"
          label="占用数量">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="onwayNumber"
          label="在途数量">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="useNumber"
          label="所用数量">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="costPrice"
          label="库存金额">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
  import warehouseApi from '@/network/statement/repertory/warehouse/index.js';

  export default{
    data() {
      return{
        collectData: "",
        tableData: "",
      }
    },
    mounted(){
      this.getCollectData()
      this.getList()
    },
    methods: {
      // 获取仓库汇总信息
      getCollectData(){
        warehouseApi.findDepotFormReport().then((res)=>{
          if(res.code == 200){
            this.collectData = res.data
          }
        })
      },
      // 获取仓库列表信息
      getList(){
        warehouseApi.findDepotFormListReport().then((res)=>{
          if(res.code == 200){
            this.tableData = res.data
          }
        })
      },
      // 金额展示添加逗号处理
      divisionMoney(val){
        if(val){
          let str = (val + '').split('.')
          let intSum = str[0].replace(/\B(?=(?:\d{3})+$)/g, ',')
          let dot = str[1] ? '.' + str[1] : ''
          let num = intSum + dot
          return num
        }else{
          return 0
        }
      },
    }
  }
</script>

<style lang="less">
</style>