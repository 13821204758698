
import {request} from "@/network/request.js";
import qs from 'qs';

export default{
  // 查询仓库列表信息
  findDepotFormListReport(){
    return request({
      method:'POST',
      url:'/report/depotForm/findDepotFormListReport',
    })
  },
  // 查询仓库汇总信息
  findDepotFormReport(data){
    return request({
      method:'POST',
      url:'/report/depotForm/findDepotFormReport',
      data
    })
  },
}